// hooks
import useAuth from "../hooks/useAuth";
//
import createAvatar from "../utils/createAvatar";
import { MAvatar } from "./@material-extend";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user.avatar}
      alt={user.name}
      color={user.avatar ? "default" : createAvatar(user.name).color}
      {...other}
    >
      {createAvatar(user.name).name}
    </MAvatar>
  );
}
