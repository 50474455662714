// src/contexts/RoleContext.js

import { createContext, useState } from "react";

// Create a context for the role
const RoleContext = createContext();

// Create a provider component
export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState("guest"); // Default role

  const value = {
    role,
    setRole,
  };

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export { RoleContext };
