import PropTypes from "prop-types";
import useRole from "src/hooks/useRole";
import Page404 from "src/pages/Page404";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { role } = useRole();
  if (!accessibleRoles.includes(role)) {
    return <Page404 />;
  }

  return <>{children}</>;
}
