import { createSlice } from "@reduxjs/toolkit";
// utils
import { find } from "lodash";
import axios from "../../utils/axios";

// ----------------------------------------------------------------------
export function getAddressStringFromAddress(address) {
  if (address) {
    const { country, street1, street2, city } = address;
    return `${street1} , ${street2} , ${city} , ${country}`;
  }
}

export function mapOrderToInvoice(order) {
  return {
    id: order?._id || "",
    taxes: 5,
    discount: 10,
    status: order?.status || "",
    invoiceFrom: {
      name: "Solibelle Imitation & Jewellery",
      address: "DieSachbearbeiter Choriner Straße 49 10435 India",
      company: "Durgan Group",
      email: "support@solibelle.in",
      phone: "227-940-9869",
    },
    invoiceTo: {
      name:
        order?.user?.name ||
        order?.shippingAddress?.name ||
        order?.billingAddress?.firstName +
          " " +
          order?.billingAddress?.lastName,
      address:
        getAddressStringFromAddress(order?.shippingAddress) ||
        getAddressStringFromAddress(order?.billingAddress),
      company:
        order?.shippingAddress?.name ||
        order?.billingAddress?.firstName +
          " " +
          order?.billingAddress?.lastName ||
        order?.user?.name,
      email:
        order?.shippingAddress?.email ||
        order?.billingAddress?.email ||
        order?.user?.email,
      phone:
        order?.shippingAddress?.phone ||
        order?.billingAddress?.phone ||
        order?.user?.phone,
    },
    dashboard: null,
    items:
      order?.items?.map((item) => ({
        id: item.productId,
        image: item.thumbnail,
        title: item.title,
        description:
          item.color && item.size
            ? `Color : ${item.color}\n Size : ${item.size}`
            : ``,
        qty: item.cartQuantity,
        price: item.price,
      })) || [],
  };
}
const initialState = {
  isLoading: false,
  error: false,
  order: null,
  invoice: {
    id: "",
    taxes: 5,
    discount: 10,
    status: "paid",
    invoiceFrom: {
      name: "Solibelle Imitation & Jewellery",
      address: "DieSachbearbeiter Choriner Straße 49 10435 India",
      company: "Durgan Group",
      email: "support@solibelle.in",
      phone: "227-940-9869",
    },
    invoiceTo: {
      name: "Lesly Reichel",
      address: "Keas 69 Str. 15234, Chalandri Athens, Greece",
      company: "Stracke LLC",
      email: "kurt_durgan46@hotmail.com",
      phone: "261-433-6689",
    },
    items: [],
  },
  lists: {
    pending: [],
    confirmed: [],
    shipped: [],
    delivery: [],
  },
  dashboard: null,
  tracking: null,
  orders: [],
  labels: null,
  menifests: null,
  prints: null,
  shiprocketInvoices: null,
  couriers: [],
  awb: null,
  followers: [],
  addressBook: [],
  invoices: [],
  notifications: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    // { sold, balance, profit, categories, income, orders }
    getDashboardSuccess(state, action) {
      state.isLoading = false;
      state.dashboard = action.payload;
    },
    // GET ORDERS
    getOrders(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
      state.lists = {
        pending: [],
        confirmed: [],
        shipped: [],
        delivery: [],
      };
      for (const order of state.orders) {
        state.lists[order.status].push(order);
      }
    },

    // GET ORDERS
    getOrderCourierServiceAbility(state, action) {
      state.isLoading = false;
      state.couriers = action.payload?.data?.available_courier_companies || [];
    },

    // GET ORDERS
    getOrderAWB(state, action) {
      state.isLoading = false;
      state.awb = action.payload;
    },

    getOrderPickUp(state, action) {
      state.isLoading = false;
      state.pickup = action.payload;
    },

    getOrderTrackingSuccess(state, action) {
      state.isLoading = false;
      state.tracking = action.payload;
    },

    removeOrder(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // GET SINGLE ORDER
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
      state.awb = action.payload?.awb?.response?.data;
      state.pickup = action.payload?.pickup;

      if (action.payload) {
        state.invoice = mapOrderToInvoice(action.payload);
      }
    },

    getOrderFromOrdersSuccess(state, action) {
      state.isLoading = false;
      state.order = find(state.orders, { _id: action.payload });
      state.awb = state.order?.awb?.response?.data;
      state.pickup = state.order?.pickup;

      if (action.payload) {
        state.invoice = mapOrderToInvoice(state.order);
      }
    },
    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },
    // GET MENIFESTS
    getMenifestsSuccess(state, action) {
      state.isLoading = false;
      state.menifests = action.payload;
    },
    // GET PRINT MENIFESTS
    getMenifestPrintsSuccess(state, action) {
      state.isLoading = false;
      state.prints = action.payload;
    },
    // GET SHIPROCKET INVOICES
    getShiprocketInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.shiprocketInvoices = action.payload;
    },
    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.orders = state.orders.filter(
        (order) => order._id !== action.payload
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteUser } = slice.actions;

export function getCleanUpOrder() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getOrderSuccess(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDashboardData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/orders/dashboard`);
      dispatch(slice.actions.getDashboardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function addOrderToShiprocket(orderId, order) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/orders/ship/${orderId}`, order);
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function requestForCourierServiceAbility(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/orders/couriers/${orderId}`);
      dispatch(slice.actions.getOrderCourierServiceAbility(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function requestForPickUpOrder(orderId, pickup_date) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/orders/pick-up/${orderId}`, {
        pickup_date,
      });
      dispatch(slice.actions.getOrderPickUp(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function requestForAssignAWB(orderId, courier_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/orders/assign-awb/${orderId}`, {
        courier_id,
      });
      dispatch(slice.actions.getOrderAWB(response?.data?.response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/orders`);
      dispatch(slice.actions.getOrders(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrder(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/orders/${orderId}`);
      dispatch(slice.actions.removeOrder(orderId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSingleOrder(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/orders/${id}`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSingleOrderFromOrders(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getOrderFromOrdersSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderLabel(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`/orders/generate/label/${id}`);
      return data.label_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderManifest(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`/orders/generate/manifest/${id}`);
      return data.manifest_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderManifestPrint(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`/orders/generate/manifest/print/${id}`);
      return data.manifest_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderInvoice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`/orders/generate/invoice/${id}`);
      return data.invoice_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderTracking(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`/orders/track/orderId/${id}`);
      dispatch(slice.actions.getOrderTrackingSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderLabels(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.post(`/orders/generate/labels`, { ids });
      console.log(data);

      dispatch(slice.actions.getLabelsSuccess(data));
      return data.label_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderManifests(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.post(`/orders/generate/manifests`, { ids });
      dispatch(slice.actions.getMenifestsSuccess(data));
      return data.manifest_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderManifestPrints(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.post(`/orders/generate/manifest/prints`, {
        ids,
      });
      dispatch(slice.actions.getMenifestPrintsSuccess(data));
      return data.manifest_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestOrderInvoices(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.post(`/orders/generate/invoices`, { ids });
      dispatch(slice.actions.getShiprocketInvoicesSuccess(data));
      return data.invoice_url;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
