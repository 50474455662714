// material
import { Box, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
//
import { useTheme } from "@material-ui/core/styles";

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background?.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  return (
    <RootStyle {...other}>
      <Box sx={{ width: 64, height: 64 }}>
        <>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                <stop offset="50%" stopColor={PRIMARY_MAIN} />
                <stop offset="100%" stopColor={PRIMARY_DARK} />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </>
      </Box>
    </RootStyle>
  );
}
