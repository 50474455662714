import PropTypes from "prop-types";
// material
import { Box } from "@material-ui/core";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 150, height: 50, mt: 2, ...sx }}>
      <img src={"/static/brand/logo_full.svg"} alt={"solibelle"} />
    </Box>
  );
}
