import { createSlice } from "@reduxjs/toolkit";
import { filter, find, map, reject, sum, uniqBy } from "lodash";
// utils
import axios from "../../utils/axios";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  skus: [],
  product: null,
  coupon: {},
  coupons: [],
  sortBy: null,
  top: [],
  tags: [],
  gifts: [],
  styles: [],
  categories: [],
  specifications: [],
  offers: [],
  offer: null,
  filters: {
    tags: [],
    categories: [],
    gender: [],
    colors: [],
    priceRange: "",
    rating: "",
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
      state.skus = Object.fromEntries(
        map(action.payload, (product) => [product.sku, product._id])
      );
      state.categories = getUniqueFromArrayOfObject(action.payload, "category");
      state.tags = getUniqueFromArrayOfObject(action.payload, "tag");
      state.gifts = getUniqueFromArrayOfObject(action.payload, "gift");
      state.styles = getUniqueFromArrayOfObject(action.payload, "style");
    },

    getTopProductsSuccess(state, action) {
      state.isLoading = false;
      state.top = action.payload;
    },

    // GET PRODUCT
    createProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET PRODUCT
    createProductCouponSuccess(state, action) {
      state.isLoading = false;
      state.coupon = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET SPECIAL OFFERS
    getSpecialOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload;
    },

    getCleanUpSpecialOfferSuccess(state, action) {
      state.isLoading = false;
      state.offer = null;
    },

    getSpecialOfferFromOffersSuccess(state, action) {
      state.isLoading = false;
      state.offer = find(state.offers, { _id: action.payload });
      state.offer = { ...state.offer };
      console.log(state.offer, action.payload);
    },
    // GET SPECIAL OFFERS
    getSpecialOfferSuccess(state, action) {
      state.isLoading = false;
      state.offer = action.payload;
    },

    // GET PRODUCT
    getProductCouponsSuccess(state, action) {
      state.isLoading = false;
      state.coupons = action.payload;
    },

    getProductSpecificationsSuccess(state, action) {
      state.isLoading = false;
      state.specifications = action.payload;
    },

    // DELETE PRODUCT
    deleteProduct(state, action) {
      state.products = reject(state.products, { _id: action.payload });
    },

    // DELETE PRODUCT
    deleteProductCouponSuccess(state, action) {
      state.coupons = reject(state.coupons, { _id: action.payload });
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.categories = action.payload.categories;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], "id");
    },

    deleteCart(state, action) {
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity,
  getCleanUpSpecialOfferSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

function getUniqueFromArrayOfObject(data, key) {
  // Flatten the arrays of categories
  const allCategories = data.flatMap((item) => item[key]);

  // Create a Set from the flattened array to remove duplicates
  const uniqueCategories = new Set(allCategories);

  // Convert the Set back to an array (optional)
  return Array.from(uniqueCategories);
}

// ----------------------------------------------------------------------
export function deleteProductFromApi(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/products/${productId}`);
      if (response.data) {
        dispatch(slice.actions.deleteProduct(productId));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProduct(id, product) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/products/${id}`, product);
      dispatch(slice.actions.createProductSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error.response.data));
    }
  };
}

// ----------------------------------------------------------------------

export function createProduct(product) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products`, product);
      dispatch(slice.actions.createProductSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error.response.data));
    }
  };
}

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpecialOfferForEdit(offerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSpecialOfferFromOffersSuccess(offerId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductSpecifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/specification`);
      dispatch(slice.actions.getProductSpecificationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTopProducts(limit = 5) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products`, { params: { limit } });
      dispatch(slice.actions.getTopProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/${id}`);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpecialOffers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/offers`);
      dispatch(slice.actions.getSpecialOffersSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getSpecialOffer(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/offers/${id}`);
      dispatch(slice.actions.getSpecialOfferSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSpecialOffers(offer) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/offers`, offer);
      dispatch(getSpecialOffers());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function editSpecialOffer(id, offer) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/offers/${id}`, offer);
      dispatch(getSpecialOffers());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSpecialOffer(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/offers/${id}`);
      dispatch(getSpecialOffers());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createProductCoupon(coupon) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/products/coupons`, coupon);
      dispatch(slice.actions.createProductCouponSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductCoupons() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/products/coupons`, {
        params: { all: true },
      });
      dispatch(slice.actions.getProductCouponsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProductCoupon(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      axios.delete(`/products/coupons/${id}`);
      dispatch(slice.actions.deleteProductCouponSuccess(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
