import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  banners: [],
  texts: [],
  budgets: [],
  categories: [],
  screenProducts: [],
};

const slice = createSlice({
  name: "site",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    getScreenProductSuccess(state, action) {
      state.isLoading = false;
      state.screenProducts = action.payload;
    },

    deleteScreenProductSuccess(state, action) {
      state.isLoading = false;
      state.screenProducts = state.screenProducts.filter(
        (item) => item._id === action.payload
      );
    },

    updateScreenProductSuccess(state, action) {
      state.isLoading = false;
      state.screenProducts = state.screenProducts.map((item) => {
        if (item.title === action.payload.title) {
          return action.payload; // Update the 'status' property
        }
        return item; // Return the order unchanged if the condition is not met
      });
    },

    // GET BANNERS
    getBannersSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload;
    },

    getBudgetsSuccess(state, action) {
      state.isLoading = false;
      state.budgets = action.payload;
    },

    // GET BANNER TEXT
    getBannerTextsSuccess(state, action) {
      state.isLoading = false;
      state.texts = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getScreenProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/manage/screen-product");
      dispatch(slice.actions.getScreenProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createScreenProducts(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post("/manage/screen-product", data);
      dispatch(getScreenProducts());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateScreenProducts(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put("/manage/screen-product", data);
      dispatch(slice.actions.updateScreenProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteScreenProducts(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/manage/screen-product/${id}`);
      dispatch(slice.actions.deleteScreenProductSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBanners() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/manage/banner-image");
      dispatch(slice.actions.getBannersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function setBanners(banners) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/manage/banner-image", banners);
      dispatch(slice.actions.getBannersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBannerTexts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("manage/banner-text");
      dispatch(slice.actions.getBannerTextsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setBannerTexts(texts) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("manage/banner-text", texts);
      dispatch(slice.actions.getBannerTextsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBannerTexts(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`manage/banner-text/${id}`);
      dispatch(slice.actions.getBannerTextsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBannerImage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`manage/banner-image/${id}`);
      dispatch(slice.actions.getBannersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBudget(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`manage/budget/${id}`);
      dispatch(slice.actions.getBudgetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBudget() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`manage/budget`);
      dispatch(slice.actions.getBudgetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createBudget(budget) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`manage/budget`, budget);
      dispatch(slice.actions.getBudgetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setCategories(categories) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("manage/categories", categories);
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/manage/categories`);
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
