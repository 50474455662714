// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// hooks
import useAuth from "./hooks/useAuth";

// components
import GoogleAnalytics from "./components/GoogleAnalytics";
import LoadingScreen from "./components/LoadingScreen";
import NotistackProvider from "./components/NotistackProvider";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemeLocalization from "./components/ThemeLocalization";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import Settings from "./components/settings";

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <Settings />
              <ScrollToTop />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
