// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import briefcaseFill from "@iconify/icons-eva/briefcase-fill";
import { Icon } from "@iconify/react";
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    roles: ["super-admin"],
    items: [
      // {
      //   title: "app",
      //   roles: ["super-admin"],
      //   path: PATH_DASHBOARD.general.app,
      //   icon: ICONS.dashboard,
      // },
      {
        title: "e-commerce",
        roles: ["super-admin"],
        path: PATH_DASHBOARD.general.ecommerce,
        icon: ICONS.ecommerce,
      },
      // {
      //   title: "analytics",
      //   roles: ["super-admin"],
      //   path: PATH_DASHBOARD.general.analytics,
      //   icon: ICONS.analytics,
      // },
      // {
      //   title: "banking",
      //   roles: ["super-admin"],
      //   path: PATH_DASHBOARD.general.banking,
      //   icon: ICONS.banking,
      // },
      // {
      //   title: "booking",
      //   path: PATH_DASHBOARD.general.booking,
      //   icon: ICONS.booking,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    roles: ["super-admin", "admin", "blogger"],
    items: [
      // MANAGEMENT : USER
      {
        title: "user",
        roles: ["super-admin", "hr"],
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: "profile", path: PATH_DASHBOARD.user.profile },
          // { title: "cards", path: PATH_DASHBOARD.user.cards },
          // { title: "account", path: PATH_DASHBOARD.user.account },
          // { title: "edit", path: PATH_DASHBOARD.user.editById },
          { title: "list", path: PATH_DASHBOARD.user.list },
          { title: "create", path: PATH_DASHBOARD.user.newUser },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: "e-commerce",
        roles: ["super-admin", "admin"],
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: "site", path: PATH_DASHBOARD.eCommerce.site },
          { title: "shop", path: PATH_DASHBOARD.eCommerce.shop },
          { title: "list", path: PATH_DASHBOARD.eCommerce.list },
          { title: "create", path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: "coupon", path: PATH_DASHBOARD.eCommerce.coupon },
          { title: "offers", path: PATH_DASHBOARD.eCommerce.offers },

          // { title: "product", path: PATH_DASHBOARD.eCommerce.productById },
          // { title: "edit", path: PATH_DASHBOARD.eCommerce.editById },
          // { title: "checkout", path: PATH_DASHBOARD.eCommerce.checkout },
          // { title: "invoice", path: PATH_DASHBOARD.eCommerce.invoice },
        ],
      },

      // MANAGEMENT : ORDER
      {
        title: "order",
        roles: ["super-admin"],
        path: PATH_DASHBOARD.order.list,
        icon: <Icon icon={briefcaseFill} />,
        children: [
          { title: "list", path: PATH_DASHBOARD.order.list },
          { title: "manage", path: PATH_DASHBOARD.order.manage },
          // { title: "invoice", path: PATH_DASHBOARD.order.invoice },
        ],
      },

      // MANAGEMENT : BLOG
      {
        title: "blog",
        roles: ["super-admin", "blogger"],
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: "posts", path: PATH_DASHBOARD.blog.posts },
          // { title: "post", path: PATH_DASHBOARD.blog.postById s},
          { title: "new post", path: PATH_DASHBOARD.blog.newPost },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: "app",
    roles: ["super-admin"],
    items: [
      {
        title: "mail",
        roles: ["super-admin"],
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">2</Label>,
      },
      {
        title: "chat",
        roles: ["super-admin"],
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
      {
        title: "calendar",
        roles: ["super-admin"],
        path: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar,
      },
      {
        title: "kanban",
        roles: ["super-admin"],
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
      },
    ],
  },
];

export default sidebarConfig;
