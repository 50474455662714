import axios from "axios";

// ----------------------------------------------------------------------
export const BASE_URL = "https://api.solibelle.in";
// export const BASE_URL = "http://127.0.0.1:5000";
export const ACCESS_TOKEN = "accessToken";

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
